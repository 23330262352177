import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListPosts extends Component {
  render() {
    const blocks = buildBlocks(
      this.props.data.wpPage.blocks,
      { posts: this.props.data.allWpPost.edges },
      { posts: this.props.data.allWpPost.edges, ...this.props.pageContext }
    )
    return (
      <Layout
        meta={this.props.data.wpPage.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPage.title}
        slug={this.props.data.wpPage.slug}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String, $ids: [Int]) {
    wpPage(slug: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    allWpPost(
      filter: { databaseId: { in: $ids } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          excerpt
          date(formatString: "DD MMMM YYYY")
          categories {
            nodes {
              id
              termTaxonomyId
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 960
                    aspectRatio: 1.35
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ListPosts
